.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-checkbox {
  outline: none !important;
  margin-right: 0px !important;
}

.ant-checkbox-inner {
  margin-right: 0px !important;
}

.ant-checkbox .ant-checkbox-input {
  outline: none !important;
}

.ant-select-selector {
  padding-left: 0px !important;
}

.ant-select-selection-item {
  font-size: 18px !important;
}

.ant-select-selection-search {
  inset-inline-start: 0px !important;
}

.avatar-title {
  background-color: rgb(234 234 234) !important;
  img {
    width: 100%;
  }
}

.notlooked {
  .d-flex.align-items-center {
    background-color: #f5f7fa83;
    &:hover {
      background-color: #f5f7fae7;
    }
  }
  h6 {
    color: #000 !important;
  }
  p {
    color: rgba(0, 0, 0, 0.8) !important;
  }
}

.toptabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e5ebf1;
  width: fit-content;
  border-radius: 8px;
  position: relative;
  padding: 0 1px;
  &::before {
    content: " ";
    background-color: #fff;
    transition: all 0.4s ease;
    border-radius: 8px;
    width: 120px;
    position: absolute;
    height: 27px;
    top: 3px;
    left: 3px;
  }
  &.a1 {
    &::before {
      left: 3px;
    }
  }
  &.a2 {
    &::before {
      left: 120px;
    }
  }
  &.a3 {
    &::before {
      left: 240px;
    }
  }
  &.a4 {
    &::before {
      left: 360px;
    }
  }
  &.a5 {
    &::before {
      left: 480px;
    }
  }
  &.a6 {
    &::before {
      left: 500px;
    }
  }
  &.a7 {
    &::before {
      left: 520px;
    }
  }
  .toptabs_item {
    padding: 6px 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    position: relative;
    cursor: pointer;
    width: 120px;
    text-align: center;
  }
}

.xbutton {
  display: inline-block;
  text-decoration: none;
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  margin-right: 12px;
  transition: all 0.4s ease;
  color: #000;
  &:hover {
    background-color: #f4f7f9;
  }
  &:last-child {
    margin-right: 0px;
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }
  &.clear {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      background-color: transparent !important;
      color: #000;
    }
    &.colored {
      color: #6c6ff5 !important;
      &:hover {
        color: #2429f0 !important;
      }
    }
  }
}

.superbar {
  overflow-y: scroll !important;
  .simplebar-wrapper {
    display: none !important;
  }
  .suppt {
    color: rgba(0, 0, 0, 0.5);
  }
  .supmt {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
    a {
      color: #6c6ff5;
      transition: all 0.4s ease;
      &:hover {
        color: #2429f0 !important;
      }
    }
  }
  h6 {
    color: rgba(0, 0, 0, 0.9);
  }
}

.notification-item .d-flex {
  margin: 0 4px !important;
}

div[data-simplebar="init"] {
  overflow-y: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.posrel {
  position: relative;
  z-index: 9999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #adb5bd;
  border-radius: 4px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}


/* стили при наведении курсора на checkbox */

.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #79ace7;
}


/* стили для активного состояния чекбокса (при нажатии на него) */

.custom-checkbox:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}


/* стили для чекбокса, находящегося в фокусе */

.custom-checkbox:focus+label::before {
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}


/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */

.custom-checkbox:focus:not(:checked)+label::before {
  border-color: #80bdff;
}


/* стили для чекбокса, находящегося в состоянии disabled */

.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

.ant-carousel .slick-slider {
  padding-bottom: 42px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -5px;
}

.ant-carousel .slick-dots li {
  width: 8px !important;
  height: 8px !important;
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  height: 8px !important;
  border-radius: 60px !important;
  background: #ffffff !important;
  border: 1px solid #fff;
  opacity: 1;
}

.ant-carousel .slick-dots li.slick-active button {
  background: transparent !important;
  border: 1px solid #fff;
  opacity: 1;
}

.modal_text {
  font-size: 14px;
  color: #000;
  padding-bottom: 62px;
}

.modal_action {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  z-index: 999999999;
  background-color: #fff;
  padding: 12px 16px 12px 0px;
  border-top: 1px solid #e9e9e9;
  box-shadow: 0 6px 12px rgb(0 0 0 / 41%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: #000000;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action a {
  color: #000000;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

.modal_action button:first-child {
  color: #b6b6b6;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.ant-select-borderless .ant-select-selection-search {
  border-bottom: 1px dashed rgb(0 0 0 / 20%);
}

.LangChanger {
  box-shadow: 0px 6px 12px rgb(0 0 0 / 10%);
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: 82px;
  z-index: 99999999999999;
}

.LangChanger div {
  text-align: center;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 24px;
  transition: all 0.3s ease;
  font-size: 14px;
  color: #383d6a;
}

.LangChanger div.active {
  background-color: #000000;
  color: #fff;
}

.LangChanger div.LC_Light {
  width: 100%;
}

.LangChanger div.LC_Dark {
  width: 100%;
}

.rubb {
  font-family: "HelveticaNeueCyr" !important;
  img {
    width: 10px;
    position: relative;
    top: -2px;
    display: inline-block !important;
  }
}

@media screen and (max-width: 768px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}

.carousel_centered .slick-track {
  left: -14px;
}

.carousel_centered .slick-slide .cccardd {
  transform: scale(0.7);
  opacity: 0.7;
  transition: all 0.6s ease;
}

.carousel_centered .slick-slide.slick-center .cccardd {
  transform: scale(1);
  opacity: 1;
  box-shadow: 0 6px 22px rgba(0, 0, 0, .4);
  position: relative;
  z-index: 9999999;
}

.carousel_centered {}

.swpb_sel .ant-select-selection-item {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 117%;
  letter-spacing: 0.5px;
  color: #181818 !important;
}

.swpb_sel .ant-select-selection-search {
  border-bottom: none !important;
}

.swpb_sel .ant-select-arrow {
  color: #000 !important;
}

.swpb_sel .ant-select-selector {
  padding: 0 10px 0 20px !important;
}

.ant-select-dropdown {
  z-index: 99999999;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #858585;
}

.breadcrumbs .breadcrumbs_item {
  cursor: pointer;
}

.breadcrumbs .breadcrumbs_item.active {
  color: #fff;
}

.breadcrumbs .breadcrumbs_del {
  margin: 0 8px;
}

@media screen and (max-width: 768px) {
  .swpb_sel {
    width: 70px !important;
  }
  .ant-select-dropdown {
    min-width: 120px !important;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin: 0 auto 120px;
  .pagination_space {
    width: 24px;
  }
}

.pagination div {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #858585;
  margin: 0px 6px;
  cursor: pointer;
}

.pagination .pagination_prev {
  color: #fff !important;
  margin-right: 12px;
}

.pagination .pagination_prev img {
  width: 10px;
}

.pagination .pagination_page {}

.pagination .pagination_page.active {
  color: #fff !important;
}

.pagination .pagination_next {
  color: #fff !important;
  margin-left: 12px;
}

.pagination .pagination_next img {
  transform: rotate(180deg);
  width: 10px;
}

.desctgl {}

.desctgl .ant-collapse-header-text {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 116.5%;
  color: #FFFFFF !important;
}

.desctgl .ant-collapse-expand-icon {
  color: #fff !important;
  padding-inline-start: 12px;
}

.desctgl .ant-collapse-header {
  flex-direction: row-reverse;
  width: fit-content;
  padding: 12px 0 !important;
}

.desctgl .ant-collapse-content-box {
  padding: 12px 0 !important;
}

.desctgl .ant-collapse-content-box p {
  font-family: 'HelveticaNeueCyr';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 117.5%;
  color: #B4B4B4;
  max-width: 580px;
}

@media screen and (max-width: 768px) {
  .desctgl .ant-collapse-header-text {
    font-size: 15px !important;
  }
  .desctgl .ant-collapse-expand-icon {
    height: 14px !important;
  }
  .desctgl .ant-collapse-content-box p {
    font-size: 15px !important;
    text-align: left !important;
  }
  .desctgl .ant-collapse-content-box {
    padding-top: 0px !important;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-thumb {
  background-color: #babac07c;
  border-radius: 16px;
}

::-webkit-scrollbar-track:hover {}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body {
  overflow-x: hidden;
}

.fromfade {
  transition: all 0.8s ease;
}

.fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: all 0.8s ease;
  opacity: 1;
  z-index: 9999;
}

.fader_content {
  position: fixed;
  top: 143px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 143px);
  background-color: #000;
  transition: all 0.8s ease;
  opacity: 1;
  z-index: 9999;
}

#smooth-wrapper {
  overflow-x: hidden;
}

.fh-1 {
  height: calc(100vh - 142px);
  .headed {
    padding-top: 94px;
    p {
      font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 35px;
      color: #FFFFFF;
      margin-bottom: 50px;
      user-select: none;
    }
    button {
      background: #F4F4F4;
      border-radius: 9px;
      padding: 16px 52px;
      font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 550;
      font-size: 20px;
      line-height: 100%;
      color: #000000;
      border: none;
      outline: none;
      user-select: none;
    }
  }
  .bottomed {
    position: fixed;
    bottom: 88px;
    .backtoback {
      cursor: pointer;
      font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      color: #CAC9C9;
      img {
        margin-right: 20px;
        width: 28px;
      }
      &:hover {
        img {
          transform: translateX(0px);
          animation: arrow-tl infinite 1.6s ease-in-out;
        }
      }
    }
  }
}

@keyframes arrow-tl {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0px);
  }
}

.formed {
  .formed_item {
    margin-bottom: 16px;
    &.file {}
    .formed_item__label {
      margin-bottom: 2px;
    }
    .formed_item__input {
      input,
      textarea {
        background: #fff;
        border-radius: 9px;
        padding: 12px 16px 10px;
        font-family: 'HelveticaNeueCyr';
        font-style: normal;
        font-weight: 550;
        font-size: 16px;
        line-height: 100%;
        color: #000000;
        border: 1px solid rgb(224, 224, 224);
        outline: none;
        width: 100%;
      }
      button {
        background: #F4F4F4;
        border-radius: 9px;
        padding: 12px 20px;
        font-family: 'HelveticaNeueCyr';
        font-style: normal;
        font-weight: 550;
        font-size: 16px;
        line-height: 100%;
        color: #000000;
        border: none;
        outline: none;
        user-select: none;
      }
    }
  }
}

@media screen and (max-height: 605px) {
  .bottomed {
    position: relative !important;
    bottom: unset !important;
    padding-bottom: 88px;
    padding-top: 88px;
  }
}

@media screen and (max-width: 768px) {
  .headed {
    padding-top: 44px !important;
    p {
      font-size: 22px !important;
      line-height: 34px !important;
    }
  }
}

.ant-message {
  z-index: 99999999999;
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }
  .slick-list {
    width: calc(100% + 20px) !important;
    margin: 0 -10px !important;
  }
  .pagination {
    margin: 0 auto 28px !important;
  }
  .carousel_mob {
    .slick-dots-bottom {
      bottom: 44px !important;
      li {
        &.slick-active {
          button {
            background: transparent !important;
            border: 1px solid #222 !important;
          }
        }
        button {
          background: #222 !important;
          border: 1px solid #222 !important;
        }
      }
    }
  }
}

.logo {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.9px;
  font-weight: 700;
  text-transform: uppercase;
  display: block !important;
}

.auth-logo {
  font-size: 24px;
  color: rgb(14, 14, 14);
  letter-spacing: 0.9px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.auth-logo:hover {
  color: rgb(59, 13, 196);
}

.logo span small {
  font-size: 16px;
  font-weight: 400;
}

.logo:hover {
  color: #fff !important;
}

.vertical-menu .vertical-menu-btn {
  height: 69px;
}

.modal_text {
  font-size: 14px;
  color: #000;
}

.modal_action {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
}

.Toastify {
  position: relative;
  z-index: 9999999999999999;
  width: 100%;
}

.Toastify__toast-container {
  width: 450px !important;
  margin: 10px !important;
  right: 0px !important;
  left: unset !important;
}

.Toastify__toast {
  background: #fff !important;
  border-radius: 12px !important;
  color: #212121b3 !important;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 12%) !important;
  margin-bottom: 12px !important;
}

.modal_action div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal_action button {
  color: #8037e5;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action a {
  color: #8037e5;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 12px;
}

.modal_action button:first-child {
  color: #b6b6b6;
  font-weight: 600;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  text-transform: uppercase;
}

.modal_action button:disabled {
  color: rgb(164, 164, 164);
  cursor: default;
}

.modal_text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
}

.ant-select-dropdown {
  z-index: 999999999999 !important;
}

.bg-primary {
  background-color: #000 !important;
}

.btn-primary {
  background-color: #000 !important;
  border-color: #000 !important;
}

#layout-wrapper {
  background-color: #eff3f7 !important;
}

.vertical-menu,
.navbar-brand-box {
  background-color: #000 !important;
}

.ant-message {
  z-index: 99999999999999;
}

#sidebar-menu ul li a:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.image_uploader_inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  .iui_title {
    padding-left: 18px;
    margin-right: 20px;
    div {
      text-align: start;
      &:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
      }
      &:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        width: 200px;
      }
    }
  }
  .iui_images {
    display: flex;
    flex-direction: row;
    align-items: center;
    .iui_images_item {
      width: fit-content;
      position: relative;
      text-align: center;
      background-color: #ebebeb;
      border-radius: 8px;
      margin-right: 16px;
      img {
        margin-right: 0px !important;
      }
      small {
        position: absolute;
        width: 18px;
        height: 18px;
        right: 4px;
        top: 4px;
        border-radius: 100px;
        background-color: #000;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .image_uploader_inline {
    flex-direction: column !important;
    align-items: flex-start !important;
    .iui_images {
      margin-top: 16px;
      padding-left: 16px;
    }
  }
  .vertical-menu {
    transition: all 0.4s ease;
  }
}

.avatar_uploader {
  width: 100%;
  display: block;
  .au_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .au_pre {
      width: 300px;
      text-align: center;
      padding-top: 48px;
      font-size: 32px;
      color: #222;
      position: absolute;
      z-index: 9;
    }
    .au_image {
      height: 140px;
      width: 140px;
      border-radius: 200px;
      margin-bottom: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      z-index: 99;
    }
    .au_title {
      padding: 0 16px;
      div {
        text-align: start;
        &:nth-child(1) {
          font-size: 18px;
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}

.ant-upload-drag {
  background: rgb(250 250 250) !important;
  border: 1px solid #d9d9d9 !important;
  &:hover {
    border: 1px solid #b8b8b8 !important;
  }
}

.app-search {
  span {
    color: #c3c3c3;
  }
  .form-control {
    background-color: #f3f3f3 !important;
    transition: all 0.4s ease !important;
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
    &:focus {
      box-shadow: 0 8px 26px rgba(0, 0, 0, .2);
      background-color: #f7f7f7 !important;
    }
  }
}

.notification-item .d-flex {
  padding: .75rem 12px !important;
}

.avatar-title {
  width: 38px !important;
}

.me-3 {
  margin-right: 16px !important;
}

.np_btn {
  background: linear-gradient(90deg, #D000A5, #004DDB);
  border: none;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding: 16px 0 14px;
  height: 52px;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  &.loading_btn {
    cursor: default !important;
  }
  img {
    width: 130px;
  }
}

#sidebar-menu ul li a {
  font-size: 18px !important;
}

.breadcrumb-item {
  color: #c1cad4;
}

@media screen and (max-width: 768px) {
  .breadcrumb-item {
    font-size: 12px !important;
  }
  .page-title-box h4 {
    font-size: 14px !important;
  }
}

.search_results {
  position: absolute;
  top: 65px;
  left: 26px;
  width: 500px;
  background-color: #fff;
  box-shadow: 0 8px 26px rgba(0, 0, 0, .2);
  border-radius: 10px;
  .sr_none {
    padding: 16px;
  }
  .sr_items {
    padding: 6px;
    .sr_items__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 18px 6px 10px;
      cursor: pointer;
      transition: all 0.4s ease;
      border-radius: 10px;
      &:hover {
        background-color: rgb(241, 245, 249);
      }
      .sr_items__item_image {
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 32px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 12px;
      }
      .sr_items__item_name {
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        color: #000;
      }
      .sr_items__item_go {
        width: fit-content;
        min-width: fit-content;
      }
    }
  }
}

.xxbtn {
  transform: translateY(16px) !important;
  &.right {
    transform: rotate(180deg) translateY(-16px) !important;
  }
  svg {
    height: 26px;
    path {
      fill: #fff;
    }
  }
}