.xgtabs_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-right: 42px;
  border-radius: 10px;
  overflow: hidden;
  &.scr {
    .xgtabs {
      padding-left: 20px;
      padding-right: 20px;
    }
    .xgtabs_cont {
      &::after,
      &::before {
        //display: block;
      }
    }
  }
  .xgtabs_cont {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &::after {
      display: none;
      content: " ";
      width: 20px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: linear-gradient(-90deg, rgba(7, 53, 77, 0.06), transparent);
    }
    &::before {
      display: none;
      content: " ";
      width: 20px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background: linear-gradient(90deg, rgba(7, 53, 77, 0.06), transparent);
    }
  }
  .xgtabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    max-width: 560px;
    max-height: 45px;
    &::-webkit-scrollbar {
      height: 0px;
    }
    .xgtabs_item {
      min-width: fit-content;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 1px 1px 8px;
      background-color: aliceblue;
      border-radius: 12px;
      margin-left: 8px;
      transition: all 0.4s ease;
      color: rgb(59 107 154);
      font-size: 10px;
      letter-spacing: 0.1px;
      height: 20px;
      margin-bottom: 3px;
      &.active {
        background-color: rgb(202, 219, 234);
        color: rgb(20, 44, 68);
      }
      &:hover {
        background-color: rgb(229, 237, 244);
        color: rgb(44, 87, 130);
      }
      &.fw {
        padding: 1px 8px !important;
      }
      span.delete {
        position: relative;
        top: 0px;
        margin-left: 8px;
        background-color: #dde7ef;
        border-radius: 20px;
        width: 18px;
        height: 18px;
        font-size: 10px;
        text-align: center;
      }
    }
  }
}