.sidebar_menu {
  ul {
    li {
      a {
        color: rgba(255, 255, 255, 0.5) !important;
        &.active {
          color: rgba(255, 255, 255, 0.8) !important;
        }
      }
      span {
        &:first-child {
          color: rgba(255, 255, 255, .6);
          background-color: rgba(255, 255, 255, .12);
          display: inline-block;
          min-width: 1.5rem;
          padding-bottom: 0.125em;
          font-size: 1.1rem;
          line-height: 1.40625rem;
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          margin-right: 12px;
          text-align: center;
          vertical-align: middle;
          -webkit-transition: all .4s;
          transition: all .4s;
        }
        svg {
          color: unset !important;
          background-color: unset !important;
        }
        i {
          color: unset !important;
          background-color: unset !important;
        }
      }
    }
  }
}