.header {
  background-color: transparent;
  padding: 44px 0;
}

.logo {
  transition: all 0.3s ease;
  a {
    cursor: pointer;
    img {}
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 0;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    .menu__item {
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      color: #BABABA;
      text-decoration: none;
      margin-right: 72px;
      cursor: pointer;
      &.active {
        color: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.actions {
  padding: 10px 0px;
  .account {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(6px);
    border-radius: 23px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 117%;
    letter-spacing: 0.5px;
    color: #BABABA;
    padding: 8px 20px;
    border: none;
    outline: none;
    margin-right: 16px;
    transition: all 0.3s ease;
    &.active {
      background: rgba(255, 255, 255, 0.15);
    }
    &.active_na {
      background: rgba(255, 255, 255, 0.15);
    }
    span {
      img {
        width: 20px;
        height: 20px;
        border-radius: 100px;
      }
    }
  }
  .search {
    border: none;
    outline: none;
    background: transparent;
    margin-right: 10px;
    transition: all 0.3s ease;
    img {}
  }
  .basket {
    border: none;
    outline: none;
    background: transparent;
    position: relative;
    transition: all 0.3s ease;
    margin-right: 10px;
    img {}
    span {
      background-color: red;
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      border-radius: 100px;
      top: 13px;
      right: -3px;
    }
  }
}

.header_float {
  position: fixed;
  top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  transition: all 0.3s ease;
  .header_float_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .header_float__logo {
    background: rgb(49 49 49 / 100%);
    border-radius: 23px;
    padding: 5.5px 32px 8.5px;
    width: fit-content;
    min-width: fit-content;
    margin-right: 16px;
    height: 39px;
    cursor: pointer;
    img {
      width: 22px;
    }
  }
  .header_float__mo {
    background: rgb(49 49 49 / 100%);
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
    position: absolute;
    right: 12px;
    height: 44px;
    width: 54px;
    padding-top: 18px;
    top: 0px;
    cursor: pointer;
    .search {
      border: none;
      outline: none;
      background: none;
    }
    img {
      width: 18px;
      cursor: pointer;
    }
  }
  .header_float__sign {
    background: rgb(49 49 49 / 100%);
    border-radius: 23px;
    padding: 12px 20px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #BABABA;
    width: fit-content;
    min-width: fit-content;
    transition: color 0.4s ease;
    cursor: pointer;
    margin-left: 16px;
    &:hover {
      color: #ffffff;
    }
  }
  .header_float__search {
    color: #BABABA;
    margin-left: 16px;
    padding: 0 10px;
    cursor: pointer;
  }
  .header_float__basket {
    color: #BABABA;
    padding: 0 10px;
    cursor: pointer;
  }
  .header_float__menu {
    width: 100%;
    background: rgb(58 58 58 / 100%);
    border-radius: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .header_float__menu_item {
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      color: #989898;
      width: fit-content;
      min-width: fit-content;
      padding: 12px 20px;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.4s ease;
      &:nth-child(2) {
        width: 100%;
        text-align: center;
      }
      &:hover {
        color: #ffffff;
      }
      &.active {
        color: #ffffff;
      }
    }
  }
}

.search_wrap {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  .search_wrap__bg {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    width: 100vw;
    opacity: 0;
    height: 100vh;
    transition: all 0.4s ease;
  }
  .search_wrap__pane {
    position: absolute;
    z-index: 99999;
    top: -120px;
    width: 80vw;
    left: 10vw;
    background: #FFFFFF;
    padding: 24px 62px;
    border-radius: 6px;
    transition: all 0.4s ease;
    .search_wrap__pane__block {
      display: flex;
      flex-direction: row;
      align-items: center;
      .swpb_wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #F2F2F2;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        .swpb_wrap__select {
          .swpb_wrap__select__sel {
            color: inherit;
            .ant-select-selection-search {
              border-bottom: none !important;
            }
          }
        }
        .swpb_wrap__det {
          height: 24px;
          width: 1px;
          background-color: #A4A4A4;
          margin-left: 8px;
        }
        .swpb_wrap__input {
          width: 100%;
          input {
            width: 100%;
            padding: 18px 38px 18px 24px;
            background: #F2F2F2;
            border: none;
            outline: none;
            font-family: 'HelveticaNeueCyr';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 117%;
            /* or 16px */
            letter-spacing: 0.5px;
            &::placeholder {
              color: #989898;
            }
          }
        }
      }
      button {
        width: fit-content;
        padding: 12px 38px 16px;
        background-color: #000;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border: none;
        outline: none;
        img {
          width: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header_float {
    width: 100% !important;
    left: 0px !important;
    flex-direction: row !important;
    .header_float__logo {
      margin-left: 12px;
      height: 44px !important;
      padding-top: 8.5px !important;
    }
  }
  .logo {
    a {
      img {
        width: 42px;
      }
    }
  }
  .header {
    padding: 24px 0 40px;
  }
  .actions {
    text-align: right;
    padding: 0 0px;
  }
  .search {
    margin-right: 0 !important;
    text-align: center;
    width: 36px;
    margin-left: 10px;
  }
  .account {
    padding: 8px 9px 8px 9px;
    &.active {
      padding: 6px 7px 6px 7px !important;
    }
  }
  .search_wrap__pane {
    width: 90vw !important;
    left: 5vw !important;
    padding: 20px 16px !important;
    .search_wrap__pane__block {
      button {
        padding: 12px 16px 16px !important;
        img {
          width: 16px !important;
        }
      }
    }
  }
}

.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: all 0.3s ease;
  z-index: 9999999999;
  .mobile_menu__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 12px;
    div {
      width: 100%;
      &:nth-child(1) {
        img {
          width: 42px;
        }
      }
      &:nth-child(2) {
        text-align: right;
        padding-right: 12px;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .mobile_menu__items {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      margin-bottom: 34px;
      color: #fff;
      cursor: pointer;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      color: #FFFFFF;
      &:first-child {
        a {
          background: rgba(255, 255, 255, 0.15);
          border-radius: 23px;
          padding: 8px 20px;
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 100%;
          color: #BABABA;
          text-decoration: none;
          span {
            img {
              width: 20px;
              height: 20px;
              border-radius: 100px;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .mobile_menu__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 24px;
    width: 100%;
    .mobile_menu__social_w {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        color: #fff;
        margin-right: 16px;
        cursor: pointer;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    .col1 {
      width: 50%;
      display: block;
    }
    .col2 {
      width: 50%;
      display: flex !important;
      align-items: flex-end;
      flex-direction: column;
    }
  }
  .header_float__logo {
    position: absolute;
    left: 10px;
    top: 0px;
    margin-left: 0px !important;
  }
  .header_float__mo {
    right: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .actions {
    white-space: nowrap;
  }
}