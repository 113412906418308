.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(42, 42, 42, 0.7);
  backdrop-filter: blur(6px);
  animation-name: appear;
  animation-duration: 300ms;
  transition: all 0.3s ease;
}

.modal_close {
  position: absolute;
  top: 64px;
  right: 64px;
  font-size: 64px;
  cursor: pointer;
  z-index: 9999999999;
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
  }
}

.modal_image {
  height: 100lvh;
  padding-top: 64px;
  text-align: center;
  user-select: none;
  .image {
    height: 70lvh;
    user-select: none;
    img {
      height: 100%;
      border-radius: 10px;
      user-select: none;
    }
  }
  .title {
    margin-top: 24px;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    user-select: none;
    font-family: 'RobotoCondensed' !important;
  }
}

.modal_prev,
.modal_next {
  position: absolute;
  top: calc(50% - 32px);
  width: 64px;
  height: 64px;
  font-size: 64px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
  }
}

.modal_prev {
  left: 32px;
}

.modal_next {
  right: 32px;
}

.modal_dialog {
  width: 100%;
  max-width: 550px;
  background: #1b1b1b;
  border-radius: 10px;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 130px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 38px 0 rgb(0 0 0 / 51%), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  -webkit-animation-name: slide-in;
  -webkit-animation-duration: 0.3s;
  animation-name: slide-in;
  animation-duration: 0.3s;
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
  padding: 12px 16px 0px;
}

.modal_header {
  justify-content: space-between;
  position: relative;
  .modal_header__title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    width: 100%;
    padding-top: 6px;
    text-align: left !important;
  }
  .modal_header__close {
    cursor: pointer;
    padding: 0px 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.modal_body {
  overflow: auto;
  overflow-y: scroll;
  .modal_body__content {
    padding: 16px;
  }
}

@media screen and (max-width: 960px) {
  .modal_dialog {
    max-height: calc(100vh - 140px) !important;
  }
}

@media screen and (min-width: 1024px) {
  .modal_body {
    padding: 0 24px;
  }
  .modal_header__title {
    width: 100%;
    //padding-left: 24px;
  }
}

.modal_footer {
  border-top: 1px solid #cacaca;
  justify-content: flex-end;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}